.transMain {
  width: 1024px;
  margin: 36px auto 64px auto;
  text-align: left;
}

.transMain > table,
.transMain > h2 {
  width: 90%;
  margin: 16px auto;
}

.transMain > table {
  border-collapse: collapse;
}

.transMain thead th {
  background-color: rgb(84, 214, 214);
  color: white;
  padding: 24px 4px;
  border: 1px solid #e4e4e4;
}

.transMain tbody th {
  font-weight: 500;
  border: 1px solid #d3d1d1;
  padding: 16px 4px;
}

.transMain tbody tr:nth-child(odd) {
  background-color: #cac8c85d;
}

.transMain tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.sttBk,
.sttCi,
.sttCo {
  color: #2b8a3e;
  display: inline;
  padding: 7px 2px;
  border-radius: 7px;
}

.sttBk {
  background-color: #f6c56a;
}

.sttCi {
  background-color: #b2f6bd;
}

.sttCo {
  background-color: #d1c2fc;
}
