.auth {
  margin: 250px auto;
  text-align: center;
  width: 250px;
}

.authForm {
  margin-top: 50px;
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.authForm > input {
  height: 50px;
  padding: 5px;
  background-color: #4a97f65f;
  font-size: 1.2rem;
}


.authForm > button {
  height: 50px;
  background-color: #0071c2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
}

.errMess {
  color: rgba(255, 0, 0, 0.652);
}
